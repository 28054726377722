import '../css/Login.css';
import { useNavigate } from "react-router-dom";

function Login() {

  const navigate = useNavigate();

  return (
    <div className="login">
      <header className="login-header">
        <img className="logo" 
        src="/icons/logo.svg" 
        alt="logo"/>
      </header>
      <div className="login-title">
        <p>Benvenuto!</p>
      </div>
      <div className="login-body">
          <button >Iscriviti</button> 
          <button onClick={()=>{navigate("/home")}}>Accedi</button>
      </div>
      <div className="login-footer">
        <p>Sei un'azienda?</p>

        <p onClick={()=>{navigate("/palestra/info")}}>Sei una palestra?</p>
      </div>
    </div>
  );
}

export default Login;