// GeoAltIcon.js

import { divIcon } from 'leaflet';

const GeoAltIconSelected = divIcon({
  iconSize: [0, 0],
  iconAnchor: [8, 8],
  popupAnchor: [0, -8],
  html: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E2605A" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">' +
        '<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>' +
        '</svg>',
});

export default GeoAltIconSelected;
