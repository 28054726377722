import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { partecipaApi } from "../apis/Api";
import { Modal, Button } from 'react-bootstrap';  // Importa la modale e il pulsante da react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';  // Importa i file CSS di Bootstrap
import Barra from "../components/Barra";

function PartecipaConferma() {



  const [postAttivitaEnabled, setPostAttivitaEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);  // Aggiungi uno stato per controllare la visibilità della modale

  const location = useLocation();
  const navigate = useNavigate();

  const {
    evento,
    ruoloConfermato,
  } = location.state || {};



  // Partecipa all'evento (inserimento nel db)
  const handleSubmit = async (e) => {
    setShowModal(true);  // Mostra la modale
    e.preventDefault();

    try {

      // You may want to redirect or update the UI based on the response
      const currentDate = new Date();

      const response = await partecipaApi.post("/", {
        ruolo: ruoloConfermato,
        data_iscrizione: currentDate.toISOString(),
        id_utente: "1",
        id_evento: evento.id_evento,
      });

      if (response.status === 201) {
        console.log("Partecipazione all'evento creata con successo:", response.data.data.event);
        // Puoi eseguire azioni aggiuntive qui se necessario
      } else {
        console.error("Errore durante la partecipazione dell'evento:", response.statusText);
      }
    } catch (error) {
      console.error('Error partecipating event:', error);
      // Handle errors and update UI accordingly
      console.error("Errore nella richiesta:", error);
    }
  };

  const handleInputChange = (e) => {
    setPostAttivitaEnabled(e.target.checked);
  }

  // funzione per estrarre il giorno (numero) e il mese (stringa)
  const formatDataEvento = (dataDisponibile) => {

    const date = moment(dataDisponibile);
    const giorno = date.format('DD'); // Estrae il giorno come stringa
    const mese = date.format('MMMM'); // Estrae il mese a parole
    return { giorno: parseInt(giorno, 10), mese };
  };

  const data_evento = formatDataEvento(evento.data_disponibile);
  //const data_scadenza = formatDataEvento(evento.data_scadenza_evento);

  const convertiDataItaliano = (data) => {
    // Parsing della data utilizzando moment.js
    const dataMoment = moment(data);

    // Formattazione della data in italiano
    const dataFormattata = dataMoment.format('DD MMMM YYYY'); // Formato "DD MMMM YYYY" mostra giorno, mese e anno

    return dataFormattata;
  };


  return (
    <div className="container-partecipa">
      <div className="header-pagina">
        <button className="indietro" onClick={() => {
          navigate('/partecipa/1', {
            state: {
              evento,
              ruoloConfermato
            }
          })
        }}>
          <img src='/icons/indietro.svg' alt="indietro" className="indietro-img"></img>
          <div className="indietro-text">Scegli il tuo ruolo</div>
        </button>
      </div>
      <div className="sezione titolo">dettagli evento</div>
      {(ruoloConfermato === "Tifo" || ruoloConfermato === "Fotografo") && (
        <div className="info-partecipa">
          <div className="info-el">
            <div className="descrizione-campo">QUANDO</div>
            <div className="quando-partecipa el-partecipa">{data_evento.giorno} {data_evento.mese} {evento.slot_inizio}</div>
          </div>
          <div className="info-el">
            <div className="descrizione-campo">SPORT</div>
            <div className="sport-partecipa el-partecipa">{evento.sport}</div>
          </div>
          <div className="info-el">
            <div className="descrizione-campo">DOVE</div>
            <div className="dove-partecipa el-partecipa">{evento.nome}</div>
          </div>
          <div className="info-el">
            <div className="post-attività-partecipa">
              <div className="descrizione-campo">POST ATTIVITÀ</div>
              <div className="descrizione-attività">
                <div>
                  Cena in pizzeria
                </div>
              </div>
              <div className="checkbox-partecipa">
                <input
                  type="checkbox"
                  id="postAttivitaEnabled"
                  name="postAttivitaEnabled"
                  checked={postAttivitaEnabled}
                  onChange={handleInputChange}
                /> Partecipo alla post attività
              </div>
            </div>
          </div>


          <div className="avviso-scadenza">
            <div className="scadenza">Iscriviti entro il {convertiDataItaliano(evento.data_scadenza_evento)}</div>

          </div>
        </div>
      )}
      {(ruoloConfermato === "Giocatore" || ruoloConfermato === "Arbitro") && (
        <div className="info-partecipa">
          <div className="descrizione-campo">QUANDO</div>
          <div className="quando-partecipa el-partecipa">{data_evento.giorno} {data_evento.mese} {evento.slot_inizio}</div>
          <div className="descrizione-campo">SPORT</div>
          <div className="sport-partecipa el-partecipa">{evento.sport}</div>
          <div className="descrizione-campo">DOVE</div>
          <div className="dove-partecipa el-partecipa">{evento.nome}</div>
          <div className="descrizione-campo">LIVELLO</div>
          <div className="livello-partecipa el-partecipa">{evento.livello}</div>

          <div className="post-attività-partecipa">
            <div className="descrizione-campo">POST ATTIVITÀ</div>
            <div className="descrizione-attività">
              <div>
                Cena in pizzeria
              </div>
            </div>
            <div className="checkbox-partecipa">
              <input
                type="checkbox"
                id="postAttivitaEnabled"
                name="postAttivitaEnabled"
                checked={postAttivitaEnabled}
                onChange={handleInputChange}
              /> Partecipo alla post attività
            </div>
          </div>
          <div className="avviso-scadenza">
            <div className="scadenza">Iscriviti entro il {convertiDataItaliano(evento.data_scadenza_evento)}</div>

          </div>
        </div>
      )}
      <button type="submit" className="bottone" onClick={handleSubmit}>Partecipa</button>
      {/*modal*/}
      <Modal show={showModal} onHide={() => {
        setShowModal(false);
      }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Partecipazione completata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ti sei iscritto all'evento con successo!
        </Modal.Body>
        <Modal.Footer>
          <Button className="bottone-modale" onClick={() => { navigate('/home') }}>
            Torna alla home
          </Button>
        </Modal.Footer>
      </Modal>
      <Barra className="barra" />
    </div>

  );
}

export default PartecipaConferma;