import React, { useState, useEffect } from "react";
import { userApi, eventiConsigliatiApi , eventiFuturiApi , contaGiocatoriApi, contaArbitroApi, contaFotografoApi, contaTifosiApi } from '../apis/Api.js';
import '../css/Home.css';
import Card from '../components/Card.js';
import CardConsigliata from '../components/CardConsigliata.js';
import Barra from '../components/Barra.js';
import moment from 'moment';
import 'moment/locale/it';
import { useNavigate } from 'react-router-dom';


const Home = () => {

  // per portare i dati in Partecipa.js
  const navigate = useNavigate();

  // Stato per memorizzare i dati ottenuti dalla chiamata API
  const [utente, setUtente] = useState([]); // utente principale
  //const [utentiData, setUtentiData] = useState([]); // lista utenti
  const [eventiConsigliatiData, setEventiConsigliatiData] = useState([]); //lista eventi consigliati
  const [eventiUtenteData, setEventiUtenteData] = useState([]); // lista eventi dell'utente
  const [contaGiocatori, setContaGiocatori] = useState([]); // conta giocatori degli eventi dell'utente
  const [contaTifosi, setContaTifosi] = useState([]); // conta tifosi degli eventi dell'utente
  const [contaArbitro, setContaArbitro] = useState([]); // conta tifosi degli eventi dell'utente
  const [contaFotografo, setContaFotografo] = useState([]); // conta tifosi degli eventi dell'utente
  


  //registro utente principale + lista utenti
  useEffect(() => {
    const fetchUtentiData = async () => {
      try {
        const response = await userApi.get("/");
        setUtente(response.data[0]);
       // setUtentiData(response.data);
      } catch (err) {
        // Gestisci eventuali errori qui
        console.error("Errore durante il recupero dei dati:", err);
      }
    };

    fetchUtentiData();

  }, []);

  
  //lista eventi consigliati
  useEffect(() => {
    console.log("Fetching eventi consigliati...");
    const fetchEventiData = async () => {
        try {
            const response = await eventiConsigliatiApi.get(`/${utente.id}`);
            setEventiConsigliatiData(response.data);
            console.log("Eventi consigliati: ", response.data);
        } catch (err) {
            console.error("Errore durante il recupero dei dati:", err);
        }
    };
    fetchEventiData();
    
}, [utente]);

// lista eventi futuri dell'utente
useEffect(() => {
  console.log("Fetching eventi futuri...");
  const fetchEventiFuturiData = async () => {
      try {
          const response = await eventiFuturiApi.get(`/`);
          setEventiUtenteData(response.data);
          console.log("Eventi futuri: ", response.data);
      } catch (err) {
          console.error("Errore durante il recupero dei dati:", err);
      }
  };
 
  fetchEventiFuturiData();
  
}, [utente]);


  //NUMERO GIOCATORI
useEffect(() => {
  console.log("Numero di giocatori...");

  //conta il numero dei giocatori per un singolo evento
  const fetchContaGiocatoriPerEvento = async (idEvento) => {
    try {
     // if (eventiUtenteData && eventiUtenteData.id_evento) {
      const response = await contaGiocatoriApi.get(`/${idEvento}`);
    
      return response.data;

    //  }
    } catch (err) {
      console.error("Errore durante il recupero dei dati:", err);
      return null;
    }
  };

// Funzione per ottenere il numero di giocatori per casciun evento in eventiUtente
  const fetchContaGiocatoriData = async () => {
    try {
     
      const giocatoriResults = await Promise.all(eventiUtenteData.map(evento => fetchContaGiocatoriPerEvento(evento.id_evento)));

      setContaGiocatori(giocatoriResults);
    
      
    } catch (err) {
      console.error("Errore durante il recupero dei dati:", err);
      
    }
  };

  fetchContaGiocatoriData();
}, [eventiUtenteData]);


// NUMERO TIFOSI
useEffect(() => {
  console.log("Numero di tifosi...");


 //conta il numero dei tifosi per un singolo evento
 const fetchContaTifosiPerEvento = async (idEvento) => {
  try {
   // if (eventiUtenteData && eventiUtenteData.id_evento) {
    const response = await contaTifosiApi.get(`/${idEvento}`);
  
    return response.data;

  //  }
  } catch (err) {
    console.error("Errore durante il recupero dei dati:", err);
    return null;
  }
};

// Funzione per ottenere il numero di tifosi per casciun evento in eventiUtente
const fetchContaTifosiData = async () => {
  try {
   
    const tifosiResults = await Promise.all(eventiUtenteData.map(evento => fetchContaTifosiPerEvento(evento.id_evento)));

    setContaTifosi(tifosiResults);
   
    
  } catch (err) {
    console.error("Errore durante il recupero dei dati:", err);
    
  }
};

fetchContaTifosiData();
}, [eventiUtenteData]);


//NUMERO ARBITRO
useEffect(() => {
  console.log("Numero di tifosi...");


 //conta il numero dei arbitri per un singolo evento
 const fetchContaArbitroPerEvento = async (idEvento) => {
  try {
   // if (eventiUtenteData && eventiUtenteData.id_evento) {
    const response = await contaArbitroApi.get(`/${idEvento}`);
   
    return response.data;

  //  }
  } catch (err) {
    console.error("Errore durante il recupero dei dati:", err);
    return null;
  }
};

// Funzione per ottenere il numero di arbitri per casciun evento in eventiUtente (max 1)
const fetchContaArbitroData = async () => {
  try {
   
    const arbitroResults = await Promise.all(eventiUtenteData.map(evento => fetchContaArbitroPerEvento(evento.id_evento)));

    setContaArbitro(arbitroResults);
    console.log("NUMERO ARBITRI ti prego: ", arbitroResults[0][0].num_arbitro_partecipanti);
    
  } catch (err) {
    console.error("Errore durante il recupero dei dati:", err);
    
  }
};

fetchContaArbitroData();

}, [eventiUtenteData]);


//NUMERO FOTOGRAFO
useEffect(() => {
  

 //conta il numero dei fotografi per un singolo evento (max 2)
 const fetchContaFotografoPerEvento = async (idEvento) => {
  try {
    const response = await contaFotografoApi.get(`/${idEvento}`);
  
    return response.data;

  } catch (err) {
    console.error("Errore durante il recupero dei dati:", err);
    return null;
  }
};

// Funzione per ottenere il numero di fotografi per casciun evento in eventiUtente
const fetchContaFotografoData = async () => {
  try {
   
    const fotografoResults = await Promise.all(eventiUtenteData.map(evento => fetchContaFotografoPerEvento(evento.id_evento)));

    setContaFotografo(fotografoResults);
   
    
  } catch (err) {
    console.error("Errore durante il recupero dei dati:", err);
    
  }
};

fetchContaFotografoData();
}, [eventiUtenteData]);



// funzione per estrarre il giorno (numero) e il mese (stringa)
const formatDataEvento = (dataDisponibile) => {
  const date = moment(dataDisponibile);
  const giorno = date.format('DD'); // Estrae il giorno come stringa
  const mese = date.format('MMMM'); // Estrae il mese a parole

  return { giorno: parseInt(giorno, 10), mese };
};

//const dataUtenteFormattata  = formatDataEvento(eventiUtenteData.data_disponibile);


// funzione per navigare portare i dati in Partecipa.js
const handlePartecipaClick = (idEvento) => {
  navigate(`/partecipa/1`, { state: { evento: eventiConsigliatiData.find(e => e.id === idEvento) } });
};

const handleInvitaClickConsigliato = (idEvento) => {
  navigate(`/invita/1`, { state: { evento: eventiConsigliatiData.find(e => e.id === idEvento) } });
};


const handleInvitaClickProssimo = (idEvento) => {
  navigate(`/invita/1`, { state: { evento: eventiUtenteData.find(e => e.id === idEvento) } });
};


  return (
    <div className="app">
      <div className="home">
        <div className="notifica">
          <img className="icon" src="/icons/notifica.svg" alt="icona notifica" />
        </div>
        <header className="home-header">
          <img className="logo-home" src="/icons/logo.svg" alt="logo"/>
        </header>
        <div className="saluto">
              <p className="nome">Ciao {utente.nome}</p>
              <p className="titolo">I TUOI PROSSIMI EVENTI</p>
        </div>
        <div id="carouselExampleIndicators" className="carousel" >
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
            {eventiUtenteData.length > 0 && contaGiocatori.length > 0 && contaArbitro.length>0 && contaFotografo.length>0 
            && contaTifosi.length>0 &&(
              <Card 
                onClickInvita={() => handleInvitaClickProssimo(eventiUtenteData[0].id)}
                num_tifosi ={ contaTifosi[0][0].num_tifosi_partecipanti}
                num_arbitri = {contaArbitro[0][0].num_arbitro_partecipanti}
                num_fotografi = {contaFotografo[0][0].num_fotografi_partecipanti}
                sport={eventiUtenteData[0].sport} 
                giorno={formatDataEvento(eventiUtenteData[0].data_disponibile).giorno}
                mese={formatDataEvento(eventiUtenteData[0].data_disponibile).mese} 
                ora={eventiUtenteData[0].slot_inizio} 
                min={eventiUtenteData[0].min_giocatori} 
                luogo={eventiUtenteData[0].nome}
                livello={eventiUtenteData[0].livello} 
                ruolo={eventiUtenteData[0].ruolo_utente}
                partecipanti={contaGiocatori[0][0].num_giocatori_partecipanti} 
                max={eventiUtenteData[0].max_giocatori} 
                max_tifo={eventiUtenteData[0].max_tifo}
                post_attivita={eventiUtenteData[0].post_attivita}
              />
            )}
            </div>
            <div className="carousel-item">
            {eventiUtenteData.length > 0 && contaGiocatori.length > 0 && contaArbitro.length>0 && contaFotografo.length>0 
            && contaTifosi.length>0 &&(
              <Card 
                onClickInvita={() => handleInvitaClickProssimo(eventiUtenteData[1].id)}  
                num_tifosi = {contaTifosi[1][0].num_tifosi_partecipanti}
                num_arbitri = {contaArbitro[1][0].num_arbitro_partecipanti}
                num_fotografi = {contaFotografo[1][0].num_fotografi_partecipanti}
                sport={eventiUtenteData[1].sport} 
                giorno={formatDataEvento(eventiUtenteData[1].data_disponibile).giorno}
                mese={formatDataEvento(eventiUtenteData[1].data_disponibile).mese} 
                ora={eventiUtenteData[1].slot_inizio} 
                min={eventiUtenteData[1].min_giocatori} 
                luogo={eventiUtenteData[1].nome}
                livello={eventiUtenteData[1].livello} 
                ruolo={eventiUtenteData[1].ruolo_utente}
                partecipanti={contaGiocatori[1][0].num_giocatori_partecipanti} 
                max={eventiUtenteData[1].max_giocatori} 
                max_tifo={eventiUtenteData[1].max_tifo}
                post_attivita={eventiUtenteData[1].post_attivita}
              />
            )}
            </div>
            <div className="carousel-item">
            {eventiUtenteData.length > 0 && contaGiocatori.length > 0 && contaArbitro.length>0 && contaFotografo.length>0 
            && contaTifosi.length>0 && (
              <Card 
                onClickInvita={() => handleInvitaClickProssimo(eventiUtenteData[2].id)}
                num_tifosi = {contaTifosi[2][0].num_tifosi_partecipanti}
                num_arbitri = {contaArbitro[2][0].num_arbitro_partecipanti}
                num_fotografi = {contaFotografo[2][0].num_fotografi_partecipanti}
                sport={eventiUtenteData[2].sport} 
                giorno={formatDataEvento(eventiUtenteData[2].data_disponibile).giorno}
                mese={formatDataEvento(eventiUtenteData[2].data_disponibile).mese} 
                ora={eventiUtenteData[2].slot_inizio} 
                min={eventiUtenteData[2].min_giocatori} 
                luogo={eventiUtenteData[2].nome}
                livello={eventiUtenteData[2].livello} 
                ruolo={eventiUtenteData[2].ruolo_utente}
                partecipanti={contaGiocatori[2][0].num_giocatori_partecipanti} 
                max={eventiUtenteData[2].max_giocatori} 
                max_tifo={eventiUtenteData[2].max_tifo}
                post_attivita={eventiUtenteData[2].post_attivita}
              />
            )}
            </div>
          </div>
        </div>
        <p className="titolo">EVENTI CONSIGLIATI</p>
        <div className="consigliati">
        {eventiConsigliatiData.map(evento => {
          const dataFormattata = formatDataEvento(evento.data_disponibile);
          return (
            <CardConsigliata  
              key={evento.id} 
              num_tifosi = {evento.num_tifosi_partecipanti}
              num_arbitri = {evento.num_arbitro_partecipanti}
              num_fotografi = {evento.num_fotografi_partecipanti}
              sport={evento.sport} 
              giorno={dataFormattata.giorno}
              mese={dataFormattata.mese} 
              ora={evento.slot_inizio} 
              min={evento.min_giocatori}
              luogo={evento.nome}
              livello={evento.livello} 
              partecipanti={evento.num_giocatori_partecipanti} 
              max={evento.max_giocatori}
              max_tifo={evento.max_tifo}
              post_attivita = {evento.post_attivita}
              onClickInvita={() => handleInvitaClickConsigliato(evento.id)}
              onClickPartecipa={() => handlePartecipaClick(evento.id)}
            />
              
          );
            
          })}
          </div>
      </div>
      <Barra className="barra"/>
    </div>
  );
};

export default Home;
