import axios from 'axios';

// Create an instance for the 'event' API
export const eventApi = axios.create({
    baseURL: 'http://localhost:4000/api/event',
});

// Create an instance for the 'user' API
export const userApi = axios.create({
    baseURL: 'http://localhost:4000/api/user',
});

//create an instance for the 'createevent' API
export const createEventApi = axios.create({
    baseURL: 'http://localhost:4000/api/createEvent',
});

//create an instance for the 'palestre' API
// /:sport
export const palestreApi=axios.create({
    baseURL:'http://localhost:4000/api/palestre',
})

// create an instance for the 'prenotazioni' API
// /:idpalestra/:sport
export const prenotazioniApi=axios.create({
    baseURL:'http://localhost:4000/api/prenotazioni',
})

// create an instance for the 'eventiconsigliati' API
// /:idutente
export const eventiConsigliatiApi = axios.create({
    baseURL: "http://localhost:4000/api/eventiconsigliati",
})

// create an instance for the 'eventifuturi' API

export const eventiFuturiApi = axios.create({
    baseURL: "http://localhost:4000/api/futureevent1",
})

// create an instance for the 'contaGiocatori' API
// /:idevento
export const contaGiocatoriApi = axios.create({
    baseURL: "http://localhost:4000/api/contaGiocatori",
})

// create an instance for the 'contaTifosi' API
// /:idevento
export const contaTifosiApi = axios.create({
    baseURL: "http://localhost:4000/api/contaTifosi",
})

// create an instance for the 'contaArbitro' API
// /:idevento
export const contaArbitroApi = axios.create({
    baseURL: "http://localhost:4000/api/contaArbitro",
})

// create an instance for the 'contaFotografo' API
// /:idevento
export const contaFotografoApi = axios.create({
    baseURL: "http://localhost:4000/api/contaFotografo",
})

// create an instance for the 'ruoloutente' API
// /:idutente/:idevento
export const ruoloUtenteApi = axios.create({
    baseURL: "http://localhost:4000/api/ruoloutente",
})

// create an instance for the 'partecipa' API
export const partecipaApi = axios.create({
    baseURL: "http://localhost:4000/api/partecipa",
})

// create an instance for the 'invitautente' API
//:idevento
export const invitaUtenteApi = axios.create({
    baseURL: "http://localhost:4000/api/invitaUtente",
})

// create an instance for the 'ricavanumeroeventi' API
export const numeroEventiApi = axios.create({
    baseURL: "http://localhost:4000/api/ricavanumeroeventi",
})

// create an instance for the 'ricavanumeroeventi' API
// :id_evento/:data_disponibile/:slot_inizio/:sport/:id_palestra
export const updatePrenotazioneEventoApi = axios.create({
    baseURL: "http://localhost:4000/api/updatePrenotazioneEvento",
})

// create an instance for the 'utenteiscritto' API
// :id_evento
export const utenteIscrittoApi = axios.create({
    baseURL: "http://localhost:4000/api/utenteiscritto",
})

// create an instance for the 'utenteinvitato' API
// :id_evento
export const utenteInvitatoApi = axios.create({
    baseURL: "http://localhost:4000/api/utenteinvitato",
})

// create an instance for the 'invitare' API
export const invitareApi = axios.create({
    baseURL: "http://localhost:4000/api/invitare",
})