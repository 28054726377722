import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker"; //per il calendario
import React, { useEffect, useState } from "react";
import {  prenotazioniApi, createEventApi, partecipaApi, updatePrenotazioneEventoApi } from '../apis/Api.js';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import '../css/FormDataEventoProva.css';
import '../css/FormDataEvento.css';
import Barra from '../components/Barra';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const FormDataEvento = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { formState, selectedPalestraInfo, position, markers } = location.state || {};


  const [dateDisponibili, setDateDisponibili] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [slotStart, setSlotStart] = useState([]);
  //const [slotEnd, setSlotEnd] = useState([]);
  const [postAttivitaEnabled, setPostAttivitaEnabled] = useState(false);
  const [postAttivita, setPostAttivita] = useState("");
  //const [error, setError] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("")
  //const [numeroEventi, setNumeroEventi] = useState([]);
  const [slot, setSlot] = useState("") //lo slot effettivamente selezionato con il formato giusto per il db!!!!!
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [cambiaModale, setCambiaModale] = useState(false);

  const [dateError, setDateError] = useState("");
  const [slotError, setSlotError] = useState("");
  const [attivitaError, setAttivitaError] = useState("");


  //var per visulaizzare slot orari
  let intime = "8:00"
  let outtime = "22:00"
 // const [result, setResult] = useState([]);
 const result = [];

  // DATA

  //funzione per visualizzare slot orari
  function intervals(startString, endString) {
    var start = moment(startString, "HH:mm");
    var end = moment(endString, "HH:mm");
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var current = moment(start);

    while (current <= end) {
      if (result.includes(current.format("HH:mm"))) {
        return null
      }
      else {
        if (formState.sport === "Calcio" || formState.sport === "Padel" || formState.sport === "Basket") {
          result.push(current.format("HH:mm"));
          current.add(120, "minutes");
        } if (formState.sport === "Tennis") {
          result.push(current.format("HH:mm"));
          current.add(60, "minutes");
        }
      }
    }

    return result;
  }

  intervals(intime, outtime);


  //ricavare la data delle prenotazioni
  useEffect(() => {

    const fetchAvailableDates = async () => {
      try {
        const response = await prenotazioniApi.get(`/${selectedPalestraInfo.id}/${formState.sport}`)
        const dates = response.data.map(item => item.data_disponibile);

        setDateDisponibili(dates);
      } catch (err) {
        // Gestisci eventuali errori qui
        console.error("Errore durante il recupero dei dati:", err);
      }
    };
    if (formState && selectedPalestraInfo) {
      fetchAvailableDates();
    }

  }, [formState, selectedPalestraInfo]);


  // Per vedere quali date sono selezionabili
  const isDateSelectable = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    // Verifica se la data è presente nell'array dateDisponibili
    return dateDisponibili.includes(formattedDate);
  };

  // ritoranre il valore della data selezionata e impostare la selectedDate con la nuova data selezionata
  const handleDateChange = date => {
    setSelectedDate(date);
    setDateError("");
  };

  const handleSlotClick = (e) => {
    if (selectedSlot) {
      selectedSlot.style.backgroundColor = 'white';
    }
    setSelectedSlot(e.target);
    e.target.style.backgroundColor = '#7798F8';
    setSlot(e.target.innerHTML.slice(0, 5));
    // Azzerare l'errore dello slot quando l'utente inizia a selezionare uno slot
    setSlotError("");
  };

  //prendo slot iniziale e slot finale
  useEffect(() => {

    const fetchAvailableHours = async () => {
      try {
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        const response = await prenotazioniApi.get(`/${selectedPalestraInfo.id}/${formState.sport}/${formattedDate}`)
        const hours_start = response.data.map(item => item.slot_inizio);
        setSlotStart(hours_start);

        //const hours_end = response.data.map(item => item.slot_fine);
        //setSlotEnd(hours_end);

        return response.data;
      } catch (err) {
        // Gestisci eventuali errori qui
        console.error("Errore durante il recupero dei dati:", err);
      }
    };
    if (formState && selectedPalestraInfo && selectedDate) {
      fetchAvailableHours();


    }

  }, [formState, selectedPalestraInfo, selectedDate]);


  // Funzione per ottenere la data corrente
  const getCurrentDate = () => {
    return new Date();
  };

  const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    result.setHours(23, 59, 59, 999);
    return result;
  }


  // ricava il numero di eventi esistenti per ottenere l'id dell'evento futuro
 /* useEffect(() => {

    const fetchCountEvents = async () => {
      try {
        const response = await numeroEventiApi.get(`/`)
        //const eventsNumber = response.data[0].num_eventi_esistenti;

        //setNumeroEventi(eventsNumber);
      } catch (err) {
        // Gestisci eventuali errori qui
        console.error("Errore durante il recupero dei dati:", err);
      }
    };

    fetchCountEvents();

  }, []);*/


  // Funzione per gestire la sottomissione del form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Valida la data
    if (!selectedDate) {
      setDateError("Seleziona una data");
      setModalErrorShow(true);
      return;
    }

    // Valida lo slot
    if (!slot) {
      setSlotError("Seleziona un orario disponibile");
      setModalErrorShow(true);
      return;
    }


    if (postAttivitaEnabled && !postAttivita) {
      setAttivitaError("Inserisci un testo per 'post_attivita'");
      setModalErrorShow(true);
      return;
    }

    if(((postAttivitaEnabled && postAttivita) || !postAttivitaEnabled) && slot && selectedDate){
      setCambiaModale(true);
      setModalErrorShow(true);
    }

    try {
      const currentDate = getCurrentDate();
      const futureDate = addDaysToDate(selectedDate, -3); //scadenza evento dopo 7 gioni dalla creazione (da rivedere)


      //prima chiamata API => inserimento nella tabella Eventi {livello, date_creazione, data_scadenza, post_attività}
      const response = await createEventApi.post("/", {
        livello: formState.livello,
        post_attivita: postAttivitaEnabled ? postAttivita : undefined,
        data_creazione_evento: currentDate.toISOString(),
        data_scadenza_evento: futureDate.toISOString(),
      });

      if (response.status === 201) {
        console.log("Evento creato con successo con id:", response.data.data.id);
        // Puoi eseguire azioni aggiuntive qui se necessario

        if (response.data.data.id) {
          //seconda chiamata API => inserimento nella tabella Partrcipa {ruolo, data_iscrizione, id_utente, id_evento}
          console.log("ciao2");
          const response2 = await partecipaApi.post("/", {
            ruolo: formState.ruolo,
            data_iscrizione: currentDate.toISOString(),
            id_utente: "1",
            id_evento: response.data.data.id,
          });

          console.log(response2);

          if (response2.status === 201) {
            console.log("Partecipazione all'evento creata con successo (stampa l'id):", response2.data.data.id);
            // Puoi eseguire azioni aggiuntive qui se necessario
          } else {
            console.error("Errore durante la partecipazione dell'evento:", response2.statusText);
          }


          // terza chiamata API per aggiornare la tabella Prenotazioni_palestra con data, slot e sport scelto con l'id del nuovo evento
          const response3 = await updatePrenotazioneEventoApi.post("/", {
            id_evento: response.data.data.id,
            data_disponibile: moment(selectedDate).format('YYYY-MM-DD'),
            slot_inizio: slot,
            sport: formState.sport,
            id_palestra: selectedPalestraInfo.id
          });

          console.log(response3);

          if (response3.status ===200) {
            console.log("Slot prenotato con successo:", response3);
            // Puoi eseguire azioni aggiuntive qui se necessario
           
          } else {
            console.error("Errore durante la prenotazione dello slot:", response3.statusText);
            
          }
            
        }



      } else {
        console.error("Errore durante la creazione dell'evento:", response.statusText);
      }
    } catch (err) {
      console.error("Errore nella richiesta:", err);
    }
  };

  // Funzione per gestire l'input dell'utente
  const handleInputChange = (e) => {
    setAttivitaError("");
    const { name, value } = e.target;
    if (name === "postAttivitaEnabled") {
      setPostAttivitaEnabled(e.target.checked);
      //Reset dell'errore quando l'utente modifica la casella di controllo
      //setError(null);
    } else if (name === "postAttivita") {
      setPostAttivita(value);
    }
  };


  const autoExpandTextarea = (e) => {
    // Imposta l'altezza del textarea in base al contenuto
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };
  const handleClick = () => {
    // Now you can navigate programmatically to other pages using navigate
    const formStateback = formState;
    const selectedPalestraInfoback = selectedPalestraInfo;
    const markersback = markers;
    const positionback = position;
    navigate('/nuovo_evento', {
      state: {
        formStateback,
        selectedPalestraInfoback,
        markersback,
        positionback,
      },
    });
    console.log("formstate2: ", formState);
  };


  return (

    <div className="container-data">
      <div className="header-pagina">
        <button className="indietro" onClick={handleClick}>
          <img src='/icons/indietro.svg' alt="indietro" className="indietro-img"></img>
          <div className="indietro-text">Proponi evento</div>
        </button>
      </div>
      <div className="sezione titolo">
        PROPONI EVENTO
      </div>
      <form onSubmit={handleSubmit}>
        <div className='info-quando'>
          <div className='descrizione-campo'>DATA</div>
          <DatePicker
            showIcon
            placeholderText='Seleziona una data'
            selected={selectedDate}
            disabledKeyboardNavigation
            onChange={handleDateChange}
            minDate={moment().add(7, 'days').toDate()}
            filterDate={isDateSelectable}
            calendarClassName="custom-calendar"
            fixedHeight={true}
            dateFormat="dd/MM/yy"
            className={dateError ? 'input-error' : 'input-no-error'}
          />
          {dateError && <p style={{ color: "red" }}>{dateError}</p>}
          {selectedDate && (

            <div className='slot'>
              <div className='descrizione-campo'>Orari disponibili</div>
              <div className={slotError ? 'slot-orari-error' : 'slot-orari'}>
                {result.length >= 2 && (
                  <>
                    {result.slice(0, result.length - 1).map((item, i) => {
                      const isSlotStart = slotStart.includes(item);
                      return (
                        <div
                          className='slot-item'
                          onClick={(e) => {
                            handleSlotClick(e);
                            if (isSlotStart) {
                              if (selectedSlot) {

                                selectedSlot.style.backgroundColor = 'white';
                                setSelectedSlot(e.target);
                                e.target.style.backgroundColor = '#7798F8';
                                setSlot(e.target.innerHTML.slice(0, 5));
                              } else {

                                setSelectedSlot(e.target);
                                e.target.style.backgroundColor = '#7798F8';
                                setSlot(e.target.innerHTML.slice(0, 5));
                              }
                            }
                          }}
                          key={i}
                          style={{
                            backgroundColor: isSlotStart ? 'white' : 'rgba(199, 204, 216, 0.886)',
                            color: isSlotStart ? 'black' : 'white',
                            pointerEvents: isSlotStart ? 'auto' : 'none', // Disabilita l'interazione se isSlotStart è falso
                            // Aggiungi altri stili necessari
                          }}
                        >
                          {item} - {result[i + 1]}
                        </div>

                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
          {slotError && <p style={{ color: "red" }}>{slotError}</p>}
          <div className='descrizione-campo'>POST ATTIVITà</div>
          <div className='post-attività'>
            <div>Crea post-attività</div>
            <input
              type="checkbox"
              id="postAttivitaEnabled"
              name="postAttivitaEnabled"
              checked={postAttivitaEnabled}
              onChange={handleInputChange}
            />
          </div>
          {postAttivitaEnabled && (
            <textarea
              className={attivitaError ? 'textarea-error' : 'textarea'}
              id="postAttivita"
              name="postAttivita"
              value={postAttivita}
              onChange={(e) => {
                handleInputChange(e);
                autoExpandTextarea(e);
              }}
              placeholder="Inserisci il testo per 'post_attivita'"
              rows={1} // Numero iniziale di righe
              style={{ overflowY: 'hidden' }} // Nasconde la barra di scorrimento verticale iniziale
            />
          )}
          {attivitaError && <p style={{ color: "red" }}>{attivitaError}</p>}
        </div>
        {/*  <p>Data selezionata: {moment(selectedDate).format('DD/MM/YYYY')}</p>*/}
        <button type="" className={selectedDate && selectedSlot && !(postAttivitaEnabled && !postAttivita) ? 'bottone' : 'bottone-disattivo'}>Crea Evento</button>
      </form>
      {
        cambiaModale ? (
          <Modal show={modalErrorShow} onHide={() => {
            setModalErrorShow(false);
          }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Evento creato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              L'evento è stato creato con successo!
            </Modal.Body>
            <Modal.Footer>
              <Button className="bottone-modale" onClick={() => { navigate('/home') }}>
                Torna alla home
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal show={modalErrorShow} onHide={() => {
            setModalErrorShow(false);
          }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Dati mancanti</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Compila tutti i campi prima di andare avanti
            </Modal.Body>
          </Modal>
        )
      }
      <Barra className="barra" />
    </div>
  );
};


export default FormDataEvento;