import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { invitaUtenteApi, utenteIscrittoApi, utenteInvitatoApi, invitareApi } from "../apis/Api";
import '../css/Invita.css';
import { Modal} from 'react-bootstrap';  // Importa la modale e il pulsante da react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';  // Importa i file CSS di Bootstrap
import Barra from "../components/Barra";

const Invita = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const evento = location.state.evento;

  const [utentiIscrittiData, setUtentiIscrittiData] = useState([]);
  const [invitaUtenteData, setInvitaUtenteData] = useState([]);
  const [selezionati, setSelezionati] = useState([]);
  const [utentiInvitatiData, setUtentiInvitatiData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [clickSelezionaTutti, setClickSelezionaTutti] = useState(false);  // true se tutti gli utenti sono selezionati, false altrimenti
  const checkbox = document.getElementById('seleziona-tutti');

  const [showModal, setShowModal] = useState(false);  // Aggiungi uno stato per controllare la visibilità della modale
  const [cambiaModale, setCambiaModale] = useState(false);  // Aggiungi uno stato per controllare la visibilità della modale qualle delle 2 si deve vedere
  //true se l'invito è stato inviato, false se non è stato inviato

  // Funzione per ottenere la data corrente
  const getCurrentDate = () => {
    return new Date();
  };

  //lista utenti già iscritti all'evento con ruolo annsso
  useEffect(() => {
    const fetchUtentiIscritti = async () => {
      try {
        const response = await utenteIscrittoApi.get(`/${evento.id_evento}`);
        setUtentiIscrittiData(response.data);

      } catch (err) {
        console.error("Errore durante il recupero dei dati: ", err);

      }
    };

    fetchUtentiIscritti();

  }, [evento]);

  //lista utentei da invitare
  useEffect(() => {
    const fetchInvitaUtenteData = async () => {
      try {
        const response = await invitaUtenteApi.get(`/${evento.id_evento}`);
        setInvitaUtenteData(response.data);
      } catch (err) {
        console.error("Errore durante il recupero dei dati:", err);
      }
    };
    fetchInvitaUtenteData();

  }, [evento]);

  // lista utenti già invitati
  useEffect(() => {
    const fetchUtentiInvitatiData = async () => {
      try {
        const response = await utenteInvitatoApi.get(`/${evento.id_evento}`);
        setUtentiInvitatiData(response.data);
      } catch (err) {
        console.error("Errore durante il recupero dei dati:", err);
      }
    };
    fetchUtentiInvitatiData();

  }, [evento]);

  // Funzione per gestire la selezione di una checkbox
  /*const handleCheckboxChange = (utenteId) => {
    if (selezionati.includes(utenteId)) {
      // Deseleziona
      setSelezionati(selezionati.filter(id => id !=== utenteId));
      console.log("Selezionati2: ", selezionati);

    } else {
      // Seleziona
      setSelezionati([...selezionati, utenteId]);
      console.log("Selezionati1: ", selezionati);

    }
  };*/

  const handleCheckboxChange = (utenteId) => {
    setSelezionati(prevSelezionati => {
      if (prevSelezionati.includes(utenteId)) {
        // Deseleziona
        const updatedSelection = prevSelezionati.filter(id => id !== utenteId);
        checkbox.checked = false;
        setClickSelezionaTutti(false);
        //console.log("Selezionati dopo deseleziona: ", updatedSelection);
        return updatedSelection;
      } else {
        // Seleziona
        const updatedSelection = [...prevSelezionati, utenteId];
        //console.log("Selezionati: ", updatedSelection);
        return updatedSelection;
      }
    });
  };

  // Funzione per selezionare tutti gli utenti o deselezionarli se già tutti selezionati
  const handleSelezionaTutti = () => {
    const tuttiGliUtenti = invitaUtenteData.map(utente => utente.id);

    if (clickSelezionaTutti===false) {
      // Altrimenti, seleziona tutti
      setClickSelezionaTutti(true);
      setSelezionati(tuttiGliUtenti);
      
    } else  {
      // Se tutti sono già selezionati, deseleziona tutti
      setClickSelezionaTutti(false);
      setSelezionati([]);

    }
  };

  // Funzione per inviare gli inviti
  const handleInvita = async () => {
    try {

      if (selezionati.length > 0) {
        setCambiaModale(true);
        setShowModal(true);
        setClickSelezionaTutti(false);
        const currentDate = getCurrentDate();
        for (const utenteID of selezionati) {
          await invitareApi.post("/", {
            id_evento: evento.id_evento,
            id_invitante: 1,
            id_invitato: utenteID,
            data_invito: currentDate.toISOString(),
          });
        }
      } else {
        // Mostra la modale
        setCambiaModale(false);
        setShowModal(true);
        setClickSelezionaTutti(false);
      }


      const response = await utenteInvitatoApi.get(`/${evento.id_evento}`);
      setUtentiInvitatiData(response.data);

      const response2 = await invitaUtenteApi.get(`/${evento.id_evento}`);
      setInvitaUtenteData(response2.data);
      /* navigate(`/Home`); */


    } catch (err) {
      console.error("Errore durante l'invio degli inviti:", err);
    }
    // Implementa la logica per inviare gli inviti

    setSelezionati([]);
  };

  return (
    <div className="container-invita">
      <div className="header-pagina">
          <button className="indietro" onClick={() => { navigate('/home') }}>
              <img src='/icons/indietro.svg' alt="indietro" className="indietro-img"></img>
              <div className="indietro-text">Home</div>
          </button>
        </div>
      <div className="sezione titolo">INVITA</div>
      <div className="descrizione-sezione">Seleziona o cerca i colleghi da invitare</div>
      <div className="barra-ricerca">
        <input
          type="text"
          placeholder="Cerca utente..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <img className="cerca-utente" src="/icons/search.svg" alt="cerca"></img>
      </div>
      <div class="form-check" key="seleziona-tutti" id="">
        <input class="form-check-input" type="checkbox" id="seleziona-tutti"
          onChange={handleSelezionaTutti}/>
        <label class="form-check-label" for="flexCheckDefault">
          Seleziona tutti
        </label>
      </div>
      <div className="container-tutti-nomi">
        {/* Utenti iscritti */}
        <div className="container-nomi">
          {utentiIscrittiData
            .filter((utenteIscrittoItem) =>
              `${utenteIscrittoItem.nome} ${utenteIscrittoItem.cognome}`
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
            .map((utenteIscrittoItem) => (
              <div class="form-check" key={utenteIscrittoItem.id}>
                <input class="iscritti form-check-input" type="checkbox" value="" id="flexCheckIndeterminateDisabled" checked disabled />
                <label class="nome-cognome form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                  {utenteIscrittoItem.nome} {utenteIscrittoItem.cognome} 
                </label>
                <label class="ruolo-iscritto form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                  ({utenteIscrittoItem.ruolo})
                </label>
              </div>
            ))}
        </div>

        {/* Utenti invitati */}
        < div className="container-nomi">
          {utentiInvitatiData
            .filter((utenteInvitatoItem) =>
              `${utenteInvitatoItem.nome} ${utenteInvitatoItem.cognome}`
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
            .map((utenteInvitatoItem) => (
              <div class="form-check" key={utenteInvitatoItem.id}>
                <input class="indeterminate form-check-input" type="checkbox" value="" id="flexCheckIndeterminateDisabled" checked disabled/>
                  <label class="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                    {utenteInvitatoItem.nome} {utenteInvitatoItem.cognome} 
                  </label>
              </div>
            ))}
        </div>

        {/* Utenti da invitare */}
        <div className="container-nomi">
          {invitaUtenteData
            .filter((utenteItem) =>
              `${utenteItem.nome} ${utenteItem.cognome}`
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
            .map((utenteItem) => (
              <div class="form-check" key={utenteItem.id}>
                <input class="form-check-input" type="checkbox" value={utenteItem.id} id="flexCheckDefault"
                  checked={selezionati.includes(utenteItem.id)}
                  onChange={() => handleCheckboxChange(utenteItem.id)} />
                <label class="form-check-label" for="">
                  {utenteItem.nome} {utenteItem.cognome}
                </label>
              </div>
            ))}
        </div>
      </div>
      {
        selezionati.length === 0 ? (<button className="bottone-disattivo" >Invita</button>) : (<button className="bottone" onClick={handleInvita} >Invita</button>)
      }
      {/*modal*/}
      {
        cambiaModale ? (
          <Modal show={showModal} onHide={() => {
            setShowModal(false);
          }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"> Invito completato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              L'invito è stato inviato con successo!
            </Modal.Body>
          </Modal>
        ) : (
          <Modal show={showModal} onHide={() => {
            setShowModal(false);
          }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Invito non inviato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Non hai selezionato nessun utente da invitare!
            </Modal.Body>
          </Modal>
        )
      }
      <Barra className="barra" />
    </div>
  );
}
export default Invita;    