import React, { useEffect, useState } from "react";
import { palestreApi, prenotazioniApi } from '../apis/Api.js';
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import '../css/FormDataEventoProva.css';
import GeoAltIcon from './GeoAltIcon'; // Importa l'icona personalizzata
import { useNavigate, useLocation } from 'react-router-dom';
import Barra from "../components/Barra.js";
import GeoAltIconSelected from "./GeoAltIconSelected";
import { Form, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';

const FormDataEventoProva = () => {
  //per spostarmi di pagina
  const navigate = useNavigate();
  const location = useLocation();
  //info stato precedente
  const { formStateback, selectedPalestraInfoback, positionback, markersback } = location.state || {};

  const [formState, setFormState] = useState({
    sport: "",
    livello: "",
    ruolo: "",
  });

  const [errors, setErrors] = useState({
    sport: '',
    livello: '',
    ruolo: '',
    // ... altri campi del form ...
  });

  //oggetto che si passa a pagina dopo
  // Se formStateback non è vuoto, usa i suoi valori, altrimenti usa i valori di default
  useEffect(() => {
    if (formStateback && Object.keys(formStateback).length > 0) {
      setFormState(formStateback);
      setSelectedPalestraInfo(selectedPalestraInfoback);
      setMarkers(markersback);
      setPosition(positionback);
    }
  }, [formStateback, selectedPalestraInfoback, positionback, markersback]);

  // stati per gestire i dati del form
  //const [sport, setSport] = useState("");
  // const [livello, setLivello] = useState("");
  // const [ruolo, setRuolo] = useState("");
  // const [selectedSport, setSelectedSport] = useState("");
  const [position, setPosition] = useState([45.0703, 7.6869]); //posizione di default (Torino)
  const [markers, setMarkers] = useState([]);
  const [selectedPalestraInfo, setSelectedPalestraInfo] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [selectedPalestraError, setSelectedPalestraError] = useState(false);
  const [mapContainerBorderColor, setMapContainerBorderColor] = useState('transparent'); // Initialize as an empty string


  // Opzioni per i dropdown
  const sportsOptions = [
    { label: "Calcio", minGiocatori: 11 },
    { label: "Tennis", minGiocatori: 2 },
    { label: "Basket", minGiocatori: 11 },
    { label: "Padel", minGiocatori: 2 },
  ];
  const livelliOptions = ["Principiante", "Intermedio", "Avanzato"];
  const ruoliOptions = ["Giocatore", "Tifoso", "Arbitro", "Fotografo"];

  // FUNZIONI PER LA PRIMA PARTE DEL FORM
  // Funzione per settare la posizione e le informazioni con i markers.
  useEffect(() => {
    // se selectedSport contiene un valore significa che lo sport è stato scelto e possiamo utilizzarlo come valore nella query
    if (formState.sport) {
      const fetchPalestra = async () => {
        try {
          // Effettua una richiesta API per ottenere i dati delle palestre
          const response = await palestreApi.get(`/${formState.sport}`);
          //setPalestra(response.data); // Aggiorna lo stato 'palestra' con i dati ottenuti dalla risposta API
          // entra nell'if se si trova una corrispondenza
          if (response.data.length > 0) {
            // Calcola le coordinate medie delle palestra
            const averageLat = response.data.reduce((sum, palestra) => sum + palestra.lat, 0) / response.data.length;
            const averageLong = response.data.reduce((sum, palestra) => sum + palestra.long, 0) / response.data.length;
            // Imposta la posizione della mappa con le coordinate medie
            setPosition([averageLat, averageLong]);
            // Ottiene i dati delle prenotazioni per ciascuna palestra
            const markers = await Promise.all(
              response.data.map(async (palestra) => {
                const richiestaResponse = await prenotazioniApi.get(
                  `/${palestra.id}/${formState.sport}`
                );
                const richiesta = richiestaResponse.data[0];
                // Ottiene il numero massimo di giocatori o imposta "N/A" se non disponibile
                const maxGiocatori = richiesta && richiesta.max_giocatori ? richiesta.max_giocatori : "N/A";
                // Restituisce un oggetto contenente informazioni sulla palestra e posizione
                return {

                  details: {
                    id: palestra.id,
                    nome: palestra.nome,
                    indirizzo: palestra.indirizzo,
                    maxGiocatori: maxGiocatori,
                    contatto: palestra.telefono,

                  },
                  position: [palestra.lat, palestra.long],
                  content: palestra.nome,
                };
              })
            );
            // Aggiorna lo stato 'markers' con i dati delle palestra e prenotazioni
            setMarkers(markers);

          }
        } catch (err) {
          // Gestisci eventuali errori qui
          console.error("Errore durante il recupero dei dati:", err);
        }
      };
      fetchPalestra();
    };
  }, [formState.sport]);

  // funzione per gestire il fatto che compaiano i dati sotto la mappa
  const handlePalestraSelection = (palestraId, marker) => {
    // Trova la palestra corrispondente all'ID passato come argomento
    // Imposta l'icona del marker su giallo
    setSelectedMarker(marker);
    const selected = markers.find((marker) => marker.details.id === palestraId);
    // Imposta lo stato 'selectedPalestraInfo' con i dettagli della palestra selezioanta
    setSelectedPalestraInfo(selected.details);
    setSelectedPalestraError(false);
    setMapContainerBorderColor('transparent');
  };

  //funzione per spostarmi di pagina mantenendo i dati del form
  const handleClick = () => {
    // Now you can navigate programmatically to other pages using navigate
    navigate('/nuovo_evento/data', {
      state: {
        formState,
        selectedPalestraInfo,
        markers,
        position,
      },
    });
  };


  const handleInputChange = (selectedOption, fieldName) => {
    setFormState({ ...formState, [fieldName]: selectedOption.value });
    // Cancella l'errore quando l'utente effettua una selezione
    setErrors({ ...errors, [fieldName]: '' });
  };

  const validateForm = () => {
    const newErrors = {};

    // Esempio di validazione: verifica se i campi sono vuoti
    if (!formState.sport) {
      newErrors.sport = 'Seleziona uno sport';
    }

    if (!formState.livello) {
      newErrors.livello = 'Seleziona un livello';
    }

    if (!formState.ruolo) {
      newErrors.ruolo = 'Seleziona il tuo ruolo';
    }

    if (!selectedPalestraInfo) {
      setSelectedPalestraError(true);
      setMapContainerBorderColor('#dc3545'); // Set border color to red when a gym is not selected
    } else {
      setMapContainerBorderColor('transparent'); // Reset border color when a gym is selected
    }
    setErrors(newErrors);

    // Restituisci true se il form è valido, altrimenti false
    return Object.values(newErrors).every((error) => error === '') && !selectedPalestraError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Esegui la validazione prima di procedere
    if (!validateForm()) {
      // Mostra un messaggio di avviso o esegui azioni specifiche
      setModalErrorShow(true);
      return;
    }

    // Altrimenti, procedi con la logica di invio del form
    // ... tua logica di invio del form ...
  };

  const trovaMinGiocatori = (sportSelezionato) => {
    const sportCorrispondente = sportsOptions.find(option => option.label === sportSelezionato);
    return sportCorrispondente ? sportCorrispondente.minGiocatori : 0;
  };

  console.log(mapContainerBorderColor);
  // RENDERIZZAZIONE DEL COMPONENTE
  return (
    <div className="container-form-mappa">
      <div className="header-pagina">
        <button className="indietro" onClick={() => { navigate('/home') }}>
          <img src='/icons/indietro.svg' alt="indietro" className="indietro-img"></img>
          <div className="indietro-text">Home</div>
        </button>
      </div>
      <div className="sezione titolo">
        PROPONI EVENTO
      </div>
      <div className="contenitore">
        <form className="form-mappa" onSubmit={handleSubmit}>
          <Form.Group controlId="sport">
            <Form.Label className="descrizione-sezione">SPORT</Form.Label>
            <div className={errors.sport ? 'select-container is-invalid' : 'select-container'}>
              <Select
                options={sportsOptions.map(option => ({ value: option.label, label: `${option.label} (${option.minGiocatori} giocatori min)` }))}
                value={formState.sport ? { value: formState.sport, label: `${formState.sport} (${trovaMinGiocatori(formState.sport)} giocatori min)` } : null}
                onChange={(selectedOption) => handleInputChange(selectedOption, 'sport')}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'white',
                    color: state.isSelected ? '#7798F8' : 'black',
                    fontWeight: state.isSelected ? 'bold' : 'normal',
                    borderRadius: '20px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '20px', // Applica il border-radius al menu delle opzioni
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Seleziona uno sport"
              />

            </div>
            <div className="invalid-feedback">
              {errors.sport}
            </div>
          </Form.Group>
          <Form.Group controlId="livello">
            <Form.Label className="descrizione-sezione">LIVELLO</Form.Label>
            <div className={errors.livello ? 'select-container is-invalid' : 'select-container'}>
              <Select
                options={livelliOptions.map(livello => ({ value: livello, label: livello }))}
                value={formState.livello ? { value: formState.livello, label: formState.livello } : null}
                onChange={(selectedOption) => handleInputChange(selectedOption, 'livello')}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'white',
                    color: state.isSelected ? '#7798F8' : 'black',
                    fontWeight: state.isSelected ? 'bold' : 'normal',
                    borderRadius: '20px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '20px', // Applica il border-radius al menu delle opzioni
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Seleziona un livello"
              />
            </div>
            <div className="invalid-feedback">
              {errors.livello}
            </div>
          </Form.Group>
          <Form.Group controlId="ruolo">
            <Form.Label className="descrizione-sezione">RUOLO</Form.Label>
            <div className={errors.ruolo ? 'select-container is-invalid' : 'select-container'}>
              <Select
                options={ruoliOptions.map(ruolo => ({ value: ruolo, label: ruolo }))}
                value={formState.ruolo ? { value: formState.ruolo, label: formState.ruolo } : null}
                onChange={(selectedOption) => handleInputChange(selectedOption, 'ruolo')}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'white',
                    color: state.isSelected ? '#7798F8' : 'black',
                    fontWeight: state.isSelected ? 'bold' : 'normal',
                    borderRadius: '20px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '20px', // Applica il border-radius al menu delle opzioni
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Seleziona il tuo ruolo"
              />
            </div>
            <div className="invalid-feedback">
              {errors.ruolo}
            </div>
          </Form.Group>
          {/* Aggiunta della mappa */}

          <div className="sezione-mappa">
            <label className="descrizione-sezione">PALESTRE DISPONIBILI</label>
            <div className="contenitore-mappa" style={{ border: `1px solid ${mapContainerBorderColor}` }}>
              <div style={{ marginBottom: "10px" }}>Seleziona la palestra che preferisci</div>
              <MapContainer
                key={`${position[0]}-${position[1]}`}
                center={position}
                zoom={13}
                style={{
                  height: "170px", width: "300px", borderRadius: "20px",
                }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {markers.map((marker, index) => (
                  <Marker
                    key={index}
                    position={marker.position}
                    icon={selectedMarker === marker ? GeoAltIconSelected : GeoAltIcon}
                    eventHandlers={{
                      click: () => { handlePalestraSelection(marker.details.id, marker); }
                    }}
                  >
                  </Marker>
                ))}
              </MapContainer>
              {
                selectedPalestraError &&
                <div className="errore-palestra">Seleziona una palestra</div>
              }
            </div>
          </div>
          {selectedPalestraInfo && (
            <div>
              <div className="info-palestra">
                <div className="sezione">
                  INFORMAZIONI PALESTRA SELEZIONA
                </div>
                <div className="nome">
                  {selectedPalestraInfo.nome}
                </div>
                <div className="indirizzo">
                  Indirizzo: {selectedPalestraInfo.indirizzo}
                </div>
                <div className="contatto">
                  Contatto: {selectedPalestraInfo.contatto}
                </div>
              </div>
            </div>
          )}
          {
            (formState.sport && formState.livello && formState.ruolo && selectedPalestraInfo) ?
              <Button type="submit" className="bottone" onClick={handleClick}>
                Avanti
              </Button>
              :
              <Button type="submit" className="bottone-disattivo" >
                Avanti
              </Button>
          }
        </form>
      </div>
      <Modal
        size="sm"
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Dati mancanti
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Compila tutti i campi prima di andare avanti</Modal.Body>
      </Modal>
      <Barra className="barra" />
    </div>
  );
};



export default FormDataEventoProva;