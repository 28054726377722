import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import 'moment/locale/it';
import '../css/FormDataEventoProva.css';
import '../css/Partecipa.css';
import Barra from '../components/Barra';


const Partecipa = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const evento = location.state.evento;

    const [ruolo, setRuolo] = useState(["", null]);
    const ruoloConfermato = ruolo[0]

    const ruoloSelezionato = (ruolo, elemento) => {
        if (ruolo[1] === null) {
            if (elemento.id === "arbitro") {
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Arbitro", elemento]);
            } else if (elemento.id === "fotografo") {
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Fotografo", elemento]);
            } else if (elemento.id === "giocatore") {
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Giocatore", elemento]);
            } else if (elemento.id === "tifo") {
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Tifo", elemento]);
            }
        }
        else {
            if (elemento.id === "arbitro") {
                ruolo[1].style.border = "none";
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Arbitro", elemento]);
            } else if (elemento.id === "fotografo") {
                ruolo[1].style.border = "none";
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Fotografo", elemento]);
            } else if (elemento.id === "giocatore") {
                ruolo[1].style.border = "none";
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Giocatore", elemento]);
            } else if (elemento.id === "tifo") {
                ruolo[1].style.border = "none";
                elemento.style.border = "3px solid #7798F8";
                setRuolo(["Tifo", elemento]);
            }
        }
    }
   
    return (
        <div className="container-partecipa">
            <div className="header-pagina">
                <button className="indietro" onClick={() => { navigate('/home') }}>
                    <img src='/icons/indietro.svg' alt="indietro" className="indietro-img"></img>
                    <div className="indietro-text">Home</div>
                </button>
            </div>
            <div className="sezione titolo">
                PARTECIPA
            </div>
            <div className="descrizione-sezione">Scegli il tuo ruolo</div>
            <div className="container-ruoli">
                <div className="container-ruolo" id="arbitro" onClick={(e) => { ruoloSelezionato(ruolo, e.target.closest('.container-ruolo')) }}>
                    <img src="/icons/arbitro.svg" alt="arbitro" className="icona-ruolo"></img>
                    <div className="dettagli-ruolo">
                        <div className="nome-ruolo">Arbitro</div>
                        <div className="numero-ruolo">{evento.num_arbitro_partecipanti}/2</div>
                    </div>
                </div>
                <div className="container-ruolo" id="fotografo" onClick={(e) => { ruoloSelezionato(ruolo, e.target.closest('.container-ruolo')) }}>
                    <img src="/icons/fotografo.svg" alt="fotografo" className="icona-ruolo"></img>
                    <div className="dettagli-ruolo">
                        <div className="nome-ruolo">Fotografo</div>
                        <div className="numero-ruolo">{evento.num_fotografi_partecipanti}/2</div>
                    </div>
                </div>
                <div className="container-ruolo" id="giocatore" onClick={(e) => { ruoloSelezionato(ruolo, e.target.closest('.container-ruolo')) }}>
                    <img src="/icons/giocatore.svg" alt="giocatore" className="icona-ruolo"></img>
                    <div className="dettagli-ruolo">
                        <div className="nome-ruolo">Giocatore</div>
                        <div className="numero-ruolo">{evento.num_giocatori_partecipanti}/{evento.max_giocatori}</div>
                    </div>
                </div>
                <div className="container-ruolo" id="tifo" onClick={(e) => { ruoloSelezionato(ruolo, e.target.closest('.container-ruolo')) }}>
                    <img src="/icons/tifo.svg" alt="tifo" className="icona-ruolo"></img>
                    <div className="dettagli-ruolo">
                        <div className="nome-ruolo">Tifo</div>
                        <div className="numero-ruolo">{evento.num_tifosi_partecipanti}/{evento.max_tifo}</div>
                    </div>
                </div>
            </div>
            {
                !ruolo[1] ? (<button className="bottone-disattivo" >Conferma</button>) : (<button className="bottone" onClick={() =>  navigate(`/partecipa/1/conferma`, {
                    state: {
                        evento,
                        ruoloConfermato
                    },
                })}>Conferma</button>)
            }
            <Barra className="barra" />
        </div>
    );
};

export default Partecipa;