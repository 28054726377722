import '../css/FormPalestraInfo.css';
import { useNavigate } from "react-router-dom";


function FormPalestraInfo() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="titolo">
        <p>Sei una palestra?</p>
      </div>
      <div className="descrizione">
        <p>Quali sono i vantaggi...</p>
      </div>
      <div className="titolo2">
        <p>Vuoi diventare una palestra associata?</p>
      </div>
      <button onClick={()=>{navigate('/palestra/form')}}>Compila il form</button>
    </div>
  );
}

export default FormPalestraInfo;