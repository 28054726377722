import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home.js";
import Invita from "./routes/Invita.js";
import Login from "./routes/Login.js";
import Partecipa from "./routes/Partecipa.js";
import FormDataEvento from "./routes/FormDataEvento.js";
import FormPalestra from "./routes/FormPalestra.js";
import FormPalestraInfo from "./routes/FormPalestraInfo.js";
import FormDataEventoProva from './routes/FormDataEventoProva.js';
import PartecipaConferma from "./routes/PartecipaConferma.js";


function App() {


  return (
  
      <div className="App">
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/home" element={<Home />} /> {/* CSS: OK, manca l'inserimento dei dati*/}

        
        <Route path="/nuovo_evento/data" element={<FormDataEvento/>} />
        <Route path="/nuovo_evento" element={<FormDataEventoProva/>} />

        <Route path="/palestra/info" element={<FormPalestraInfo/>} /> 
        <Route path="/palestra/form" element={<FormPalestra/>} /> {/* CSS: OK*/}
        
        <Route path="/invita/:id" element={<Invita/>} />

        <Route path="/partecipa/:id" element={<Partecipa/>} /> {}
        <Route path="/partecipa/:id/conferma" element={<PartecipaConferma/>} />
      </Routes>
      </div>
    
  );
}

export default App;
