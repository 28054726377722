import '../css/Barra.css';
import { useLocation, useNavigate } from "react-router-dom";

function Barra() {
    const navigate = useNavigate();
    const location = useLocation();

    const isCurrentPage = (pathname) => location.pathname === pathname;

    return (

        <div className='barra blocco-card'>
            <div className='casetta' onClick={() => { navigate('/home') }}>
                <svg  width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={`icon ${isCurrentPage('/home') ? 'active' : ''}`} d="M5.21429 24.5432V25.0432H5.71429H12.0434H12.5434V24.5432V16.4699H19.4566V24.5432V25.0432H19.9566H26.2857H26.7857V24.5432V11.432V11.159L26.5561 11.0114L16.2703 4.40039L16 4.22663L15.7297 4.40039L5.44394 11.0114L5.21429 11.159V11.432V24.5432ZM27.5 10.9768V25.5H18.7423V17.4267V16.9267H18.2423H13.7577H13.2577V17.4267V25.5H4.5V10.9768L16 3.59416L27.5 10.9768Z" />
                </svg>
            </div>
            <div className='tutti-eventi'>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="icon-fill" d="M24.1729 5.49242H29.0667V25.8463H2.96666V5.49242H7.86041V4.03857H9.49166V5.49242H22.5417V4.03857H24.1729V5.49242ZM7.86041 6.94627H4.59791V9.85396H27.4354V6.94627H24.1729V8.40011H22.5417V6.94627H9.49166V8.40011H7.86041V6.94627ZM4.59791 24.3924H27.4354V11.3078H4.59791V24.3924Z" />
                </svg>

            </div>
            <div className='nuovo-evento' onClick={() => { navigate('/nuovo_evento') }}>
                <svg  width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={`icon ${isCurrentPage('/nuovo_evento/data') || isCurrentPage('/nuovo_evento') ? 'active' : ''}`} d="M15 1H16C23.732 1 30 7.26801 30 15C30 22.732 23.732 29 16 29H15C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1Z" stroke-width="2" />
                    <path className={`icon-fill ${isCurrentPage('/nuovo_evento/data') || isCurrentPage('/nuovo_evento') ? 'active' : ''}`} d="M21.7939 15.2709C21.7939 15.7729 21.3869 16.18 20.8849 16.18H17.1576C16.6053 16.18 16.1576 16.6277 16.1576 17.18V20.6951C16.1576 21.2139 15.737 21.6345 15.2182 21.6345V21.6345C14.6994 21.6345 14.2788 21.2139 14.2788 20.6951V17.18C14.2788 16.6277 13.8311 16.18 13.2788 16.18H9.55152C9.04944 16.18 8.64243 15.7729 8.64243 15.2709V15.2709C8.64243 14.7688 9.04944 14.3618 9.55152 14.3618H13.2788C13.8311 14.3618 14.2788 13.9141 14.2788 13.3618V9.84662C14.2788 9.32781 14.6994 8.90723 15.2182 8.90723V8.90723C15.737 8.90723 16.1576 9.32781 16.1576 9.84662V13.3618C16.1576 13.9141 16.6053 14.3618 17.1576 14.3618H20.8849C21.3869 14.3618 21.7939 14.7688 21.7939 15.2709V15.2709Z" />
                </svg>
            </div>
            <div className='miei-eventi'>
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_173_506)">
                        <path className="icon-fill" d="M13.9242 21.5501L9.30861 17.0834C9.16754 16.924 9.09382 16.7189 9.10219 16.5092C9.11056 16.2995 9.20041 16.1005 9.35377 15.9521C9.50712 15.8037 9.71271 15.7167 9.92943 15.7086C10.1461 15.7005 10.358 15.7719 10.5228 15.9084L13.9242 19.1667L21.3642 11.9667C21.5289 11.8302 21.7408 11.7589 21.9575 11.767C22.1742 11.7751 22.3798 11.862 22.5332 12.0104C22.6865 12.1588 22.7764 12.3578 22.7848 12.5675C22.7931 12.7773 22.7194 12.9823 22.5783 13.1417L13.9242 21.5501Z"  />
                        <path className="icon-fill" d="M27.2452 5H24.5031V6.61538H27.0343V24.3846H3.40969V6.61538H5.9409V5H3.19876C3.00152 5.00316 2.80687 5.04347 2.62591 5.11866C2.44496 5.19384 2.28125 5.30241 2.14414 5.43817C2.00702 5.57392 1.89919 5.73421 1.82679 5.90987C1.7544 6.08553 1.71886 6.27312 1.72222 6.46192V24.5381C1.71886 24.7269 1.7544 24.9145 1.82679 25.0901C1.89919 25.2658 2.00702 25.4261 2.14414 25.5618C2.28125 25.6976 2.44496 25.8062 2.62591 25.8813C2.80687 25.9565 3.00152 25.9968 3.19876 26H27.2452C27.4425 25.9968 27.6371 25.9565 27.8181 25.8813C27.999 25.8062 28.1627 25.6976 28.2999 25.5618C28.437 25.4261 28.5448 25.2658 28.6172 25.0901C28.6896 24.9145 28.7251 24.7269 28.7218 24.5381V6.46192C28.7251 6.27312 28.6896 6.08553 28.6172 5.90987C28.5448 5.73421 28.437 5.57392 28.2999 5.43817C28.1627 5.30241 27.999 5.19384 27.8181 5.11866C27.6371 5.04347 27.4425 5.00316 27.2452 5Z" />
                        <path className="icon-fill" d="M8.61111 8.33341C8.83949 8.33341 9.05852 8.24562 9.22001 8.08934C9.3815 7.93306 9.47222 7.72109 9.47222 7.50008V2.50008C9.47222 2.27907 9.3815 2.06711 9.22001 1.91083C9.05852 1.75455 8.83949 1.66675 8.61111 1.66675C8.38273 1.66675 8.1637 1.75455 8.00221 1.91083C7.84072 2.06711 7.75 2.27907 7.75 2.50008V7.50008C7.75 7.72109 7.84072 7.93306 8.00221 8.08934C8.1637 8.24562 8.38273 8.33341 8.61111 8.33341Z"  />
                        <path className="icon-fill" d="M22.3889 8.33341C22.6173 8.33341 22.8363 8.24562 22.9978 8.08934C23.1593 7.93306 23.25 7.72109 23.25 7.50008V2.50008C23.25 2.27907 23.1593 2.06711 22.9978 1.91083C22.8363 1.75455 22.6173 1.66675 22.3889 1.66675C22.1605 1.66675 21.9415 1.75455 21.78 1.91083C21.6185 2.06711 21.5278 2.27907 21.5278 2.50008V7.50008C21.5278 7.72109 21.6185 7.93306 21.78 8.08934C21.9415 8.24562 22.1605 8.33341 22.3889 8.33341Z"  />
                        <path className="icon-fill" d="M11.1944 5H19.8056V6.66667H11.1944V5Z"  />
                    </g>
                    <defs>
                        <clipPath id="clip0_173_506">
                            <rect width="31" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className='profilo'>
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="icon" d="M5.16666 22.5C5.16666 21.1739 5.71101 19.9021 6.67995 18.9645C7.64888 18.0268 8.96305 17.5 10.3333 17.5H20.6667C22.0369 17.5 23.3511 18.0268 24.32 18.9645C25.289 19.9021 25.8333 21.1739 25.8333 22.5C25.8333 23.163 25.5612 23.7989 25.0767 24.2678C24.5922 24.7366 23.9351 25 23.25 25H7.75C7.06485 25 6.40777 24.7366 5.9233 24.2678C5.43884 23.7989 5.16666 23.163 5.16666 22.5Z" stroke="black" stroke-width="1.5" stroke-linejoin="round" />
                    <path className="icon" d="M15.5 12.5C17.6401 12.5 19.375 10.8211 19.375 8.75C19.375 6.67893 17.6401 5 15.5 5C13.3599 5 11.625 6.67893 11.625 8.75C11.625 10.8211 13.3599 12.5 15.5 12.5Z" stroke="black" stroke-width="1.5" />
                </svg>
            </div>
        </div>
    );

}

export default Barra;