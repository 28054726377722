import '../css/Card.css';

function Card(props) {
    return (
        <div>
            <div className="blocco-card">
                <div className="header">
                    <div className="sport">{props.sport}</div>
                    <div className='icone'>
                        <div className="giocatore">
                            {
                                (props.partecipanti === props.max) ?
                                    <img className="immagine-giocatore icona-card" src='icons/icon-giocatore-disable.png' alt="immagine giocatore" />
                                    : <img className="immagine-giocatore icona-card" src='icons/icon-giocatore-enable.svg' alt="immagine giocatore" />
                            }

                        </div>
                        <div className='arbitro'>
                            {
                                (props.num_arbitri === 2) ?
                                    <img className="immagine-arbitro icona-card" src='icons/icon-arbitro-disable.svg' alt="immagine arbitro" />
                                    : <img className="immagine-arbitro icona-card" src='icons/icon-arbitro-enable.svg' alt="immagine arbitro" />
                            }
                        </div>
                        <div className='tifo'>
                            {
                                (props.num_tifosi === props.max_tifo) ? <img className="immagine-tifo icona-card" src='icons/icon-tifo-disable.png' alt="immagine tifo" />
                                : <img className="immagine-tifo icona-card" src='icons/icon-tifo-enable.svg' alt="immagine tifo" />
                            }
                            
                        </div>
                        <div className='fotografo'>
                            {
                                (props.num_fotografi === 2) ? <img className="immagine-fotografo icona-card" src='icons/icon-fotografo-disable.png' alt="immagine fotografo" />
                                : <img className="immagine-fotografo icona-card" src='icons/icon-fotografo-enable.png' alt="immagine fotografo" />
                            }
                        </div>
                    </div>
                </div>
                <div className='contenuto'>
                    <div className="quando">
                        <div className="giorno">{props.giorno}</div>
                        <div className="mese">{props.mese}</div>
                        <div className="ora">{props.ora}</div>
                    </div>
                    <div className="informazioni">
                        <div className="min elemento">
                            <div>Minimo giocatori: {props.min}</div>
                            {
                                (props.partecipanti < props.min) ?
                                    <img className='status-icon' src='icons/warning.svg' alt="warning"></img> : <img className='status-icon' src='icons/ok.svg'alt="ok"></img>
                            }
                        </div>
                        <div className="luogo elemento">
                            <img className='icona-luogo icona-card' src='icons/luogo.svg' alt="luogo"/>
                            <div>{props.luogo}</div>
                        </div>
                        <div className="livello elemento">
                            <div>{props.livello}</div>
                        </div>
                        <div className='ruolo elemento'>
                            <div>{props.ruolo}</div>
                        </div>
                    </div>
                    <div className='bottoni-azioni'>
                        <div className='bottone-invita' onClick={props.onClickInvita}>
                            <p>Invita</p>
                        </div>
                        <button className="bottone-modifica">Modifica</button>
                    </div>
                </div>

            </div>
        </div>
    );
}





export default Card;