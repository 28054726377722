import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../css/FormPalestra.css';

function FormPalestra() {
    const navigate = useNavigate();

    // Usa uno stato per mantenere il valore del campo di input
    const [inputNome, setInputNome] = useState('');
    const [inputCognome, setInputCognome] = useState('');
    const [inputMail, setInputMail] = useState('');
    const [inputCitta, setInputCitta] = useState('');
    const [inputNomePalestra, setInputNomePalestra] = useState('');
    const [inputTelefono, setInputTelefono] = useState('');
    const [inputMessaggio, setInputMessaggio] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Verifica se tutti i campi sono compilati
        if (inputNome && inputCognome && inputMail && inputCitta && inputNomePalestra && inputTelefono.length===10 && inputMessaggio) {
            // Tutti i campi sono compilati, puoi navigare verso un'altra pagina
            navigate('/');
        } else {
            if(inputTelefono.length !== 10){
                alert('Inserire un numero di telefono valido (10 cifre)');
            } else {
            // Alcuni campi sono vuoti, mostra un avviso o esegui altre azioni necessarie
            alert('Compila tutti i campi prima di inviare il modulo.');
            }
        }
    };

    return (
        <div className="form-palestra">
            <form className="form" id="form-mio" onSubmit={handleSubmit}>
                <div>
                    <h2>Referente</h2>
                <div className="campo">
                    <label htmlFor="validationDefault01" className="form-label">Nome*</label>
                    <input type="text" className="form-control" id="validationDefault01" value={inputNome} onChange={(e)=>{setInputNome(e.target.value)}} placeholder="Mario" required/>
                </div>
                <div className="campo">
                    <label htmlFor="validationDefault02" className="form-label">Cognome*</label>
                    <input type="text" className="form-control" id="validationDefault02" value={inputCognome} onChange={(e)=>{setInputCognome(e.target.value)}} placeholder="Rossi" required/>
                </div>
                </div>
                <div className="campo">
                    <label htmlFor="validationDefaulMail" className="form-label">Email*</label>
                    <div className="input-group">
                    <input type="text" className="form-control" id="validationDefaultMail" value={inputMail} onChange={(e)=>{setInputMail(e.target.value)}} placeholder="mail@example.it" required/>
                    </div>
                </div>
                <div className="campo">
                    <label htmlFor="validationDefault03" className="form-label">Città*</label>
                    <input type="text" className="form-control" id="validationDefault03" value={inputCitta} onChange={(e)=>{setInputCitta(e.target.value)}} placeholder="Torino" required/>
                </div>
                <div className="campo">
                    <label htmlFor="validationDefault03" className="form-label">Nome palestra*</label>
                    <input type="text" className="form-control" id="validationDefault03" value={inputNomePalestra} onChange={(e)=>{setInputNomePalestra(e.target.value)}} placeholder="Palestra" required/>
                </div>
                <div className="campo">
                    <label htmlFor="validationDefault03" className="form-label">Telefono*</label>
                    <input type="text" className="form-control" id="validationDefault03" value={inputTelefono} onChange={(e)=>{setInputTelefono(e.target.value)}} placeholder="1234567890" required/>
                </div>
                <div className="campo">
                    <label htmlFor="validationDefault03" className="form-label">Messaggio*</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={inputMessaggio} onChange={(e)=>{setInputMessaggio(e.target.value)}} placeholder="Messaggio"></textarea>
                </div>
                <div className="campo">
                    <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required/>
                    <label className="form-check-label" htmlFor="invalidCheck2">
                       Accetto i termini e le condizioni
                    </label>
                    </div>
                </div>
                <div className="bott campo">
                    <button className="bottone" type="submit" required>Invia</button>
                </div>
            </form>
        </div>
    );
}

export default FormPalestra;